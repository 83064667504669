import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO
          title={data.site.siteMetadata.title}
          keywords={[`React.js`, `React`, `software`, `developer`, `Toronto`]}
        />
        <h1>Hello!</h1>

        <blockquote>
          My name is Neil Fenton and I am a Software Developer from Toronto,
          Canada
        </blockquote>

        <p>
          I am specialized in React.js, GraphQL, server-rendered React
          applications, and Node.js
        </p>

        <p>
          I currently work at <a href="https://zapier.com">Zapier</a>
        </p>
      </Layout>
    )}
  />
);

export default IndexPage;
